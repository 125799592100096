import {
  createBrowserRouter,
  isRouteErrorResponse,
  useRouteError,
} from 'react-router-dom';
import DownloadsView, {
  InvitationsView,
  ActivityView,
  CoursesView,
  CreatePathwayView,
  CredentialsView,
  CredentialView,
  IndexView,
  LearnersView,
  PathwaysView,
  PathwayView,
  ProgramsView,
  ProgramView,
  PublicProfileView,
  RegisteredUsersView,
  SkillsView,
} from './views';
import Error403 from './errors/pages/error403';
import Error404 from './errors/pages/error404';

import {
  isJSON,
  Logout,
  SsoLogin,
  getUserDataFromCookie,
  CompleteLogin,
  sentryCreateBrowserRouter,
  api,
  getUserName,
} from '@iblai/ibl-web-react-common';
import ActiveUsersView from './views/ActiveUsersView';
import EnrollmentsView from './views/EnrollmentsView';
import UnenrollmentsView from './views/UnenrollmentsView';
import CourseCompletionView from './views/CourseCompletionView';
import TimeSpentView from './views/TimeSpentView';
import GradingView from './views/GradingView';
import DashboardView from './views/DashboardView/DashboardView';
import ProfileView from './views/ProfileView';
import CredentialsListView from './views/CredentialsListView';
import StudioAuthoringCoursesView from './views/StudioAuthoringCoursesView';
import StudioAuthoringCourseAboutView from './views/StudioAuthoringCourseAboutView';
import StudioAuthoringCourseInstructorView from './views/StudioAuthoringCourseInstructorView';
import StudioAuthoringProgramAboutView from './views/StudioAuthoringProgramAboutView';
import StudioAuthoringProgramsView from './views/StudioAuthoringProgramsView';
import StudioAuthoringSettingsView from './views/StudioAuthoringSettingsView';
import LicensingContentView from './views/LicensingContentView';
import LicensingUserGroupsView from './views/LicensingUserGroupsView';
import UserLicensesView from './views/UserLicensesView';
import CourseLicensesView from './views/CourseLicensesView';
import ProgramLicensesView from './views/ProgramLicensesView';
import AssertionsViewContent from './views/content/AssertionsViewContent/AssertionsViewContent';

const authCheckFn = (shouldBeAdmin, shouldBeLoggedIn, appData, setAppData) => {
  return async () => {
    if(!shouldBeLoggedIn){
      return []
    }
    const userDataFromCookie = getUserDataFromCookie();
    if (
      shouldBeLoggedIn &&
      (!appData?.axd_token ||
        !appData?.dm_token ||
        !userDataFromCookie ||
        userDataFromCookie?.user_id !== appData.userData?.user_id)
    ) {
      throw new Response(JSON.stringify({ shouldBeLoggedIn }), {
        status: 401,
      });
    }
    return new Promise((resolve, reject) => {
      const getUserMetadataPromise = new Promise((_resolve, _reject) => {
        if (!Object.keys(appData.userMetaData).length) {
          api.ibledxusers.getUsersManageMetadata(
            { username: getUserName() },
            function (data) {
              _resolve({ userMetaData: { ...data }, userMetaDataLoaded: true });
            },
            () => {
              _reject();
            }
          );
        } else {
          resolve({});
        }
      });
      const getTenantsPromise = new Promise((_resolve, _reject) => {
        if (!localStorage.getItem('tenants')) {
          api.ibledxtenants.getUserTenants((tenants) => {
            const selectedTenant = localStorage.getItem('tenant');
            api.iblutils.saveUserTenantsDataToLocalStorage(
              tenants,
              selectedTenant
            );
            _resolve({
              current_tenant: localStorage.getItem('current_tenant'),
              tenants: localStorage.getItem('tenants'),
            });
          });
        } else {
          _resolve({});
        }
      });
      Promise.all([getUserMetadataPromise, getTenantsPromise])
        .then(([_userMetaDataInfo, _tenantInfo]) => {
          setAppData({ ...appData, ..._userMetaDataInfo, ..._tenantInfo });
          resolve([]);
        })
        .catch((error) => {
          reject('Failed to fetch User tenants / Metadata');
        });
    });
  };
};

const ErrorPage = () => {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return <Error404 />;
    } else if (error.status === 403) {
      return <Error403 redirect={process.env.REACT_APP_IBL_SPA_SKILLS_URL} />;
    } else if (error.status === 401) {
      if (JSON.parse(error.data)?.shouldBeLoggedIn) {
        if (process.env.REACT_APP_IBL_IDP_URL) {
          window.location.href = process.env.REACT_APP_IBL_IDP_URL;
        } else {
          window.location.href = `${process.env.REACT_APP_IBL_SPA_AUTH_URL}/login?redirect-to=${window.location.origin}`;
        }
      }
    }
  }
};

const route = (appData, setAppData) =>
  sentryCreateBrowserRouter([
    {
      path: '/',
      errorElement: <ErrorPage />,
      children: [
        {
          path: '',
          element: <DashboardView />,
          loader: authCheckFn(true, true, appData, setAppData),
          children: [
            {
              path: '',
              element: <IndexView />,
            },
            {
              path: 'per-learner/learners',
              element: <LearnersView />,
            },
            {
              path: 'per-learner/learners/:username',
              element: <ProfileView />,
              children: [
                {
                  path: 'activity',
                  element: <ActivityView />,
                },
                {
                  path: 'credentials',
                  element: <CredentialsView />,
                },
                {
                  path: ':credentials/:credentialID',
                  element: <CredentialView />,
                },
                {
                  path: 'pathways/:pathwayID',
                  element: <PathwayView />,
                },
                {
                  path: 'pathways/new',
                  element: <CreatePathwayView />,
                },
                {
                  path: 'skills',
                  element: <SkillsView />,
                },
                {
                  path: 'pathways',
                  element: <PathwaysView />,
                },
                {
                  path: 'courses',
                  element: <CoursesView />,
                },
                {
                  path: 'programs',
                  element: <ProgramsView />,
                },
                {
                  path: 'programs/:programID',
                  element: <ProgramView />,
                },
                {
                  path: 'public-profile',
                  element: <PublicProfileView />,
                },
              ],
            },
            {
              path: 'audience/registered-users',
              element: <RegisteredUsersView />,
            },
            {
              path: 'audience/active-users',
              element: <ActiveUsersView />,
            },
            {
              path: 'audience/enrollments',
              element: <EnrollmentsView />,
            },
            {
              path: 'licensing/platforms',
              element: <LicensingContentView />,
            },
            {
              path: 'licensing/users',
              element: <UserLicensesView />,
            },
            {
              path: 'licensing/courses',
              element: <CourseLicensesView />,
            },
            {
              path: 'licensing/programs',
              element: <ProgramLicensesView />,
            },
            {
              path: 'licensing/groups',
              element: <LicensingUserGroupsView />,
            },
            {
              path: 'audience/unenrollments',
              element: <UnenrollmentsView />,
            },
            {
              path: 'engagement/course-completion',
              element: <CourseCompletionView />,
            },
            {
              path: 'engagement/time-spent',
              element: <TimeSpentView />,
            },
            {
              path: 'performance/grading',
              element: <GradingView />,
            },
            {
              path: 'performance/credentials',
              element: <CredentialsListView />,
            },
            {
              path: 'studio/authoring/courses',
              element: <StudioAuthoringCoursesView />,
            },
            {
              path: 'studio/authoring/courses/:courseID',
              element: <StudioAuthoringCourseAboutView />,
            },
            {
              path: 'studio/authoring/assertions/:courseID/:entityID',
              element: <AssertionsViewContent />,
            },
            {
              path: 'studio/authoring/courses/:courseID/instructor',
              element: <StudioAuthoringCourseInstructorView />,
            },
            {
              path: 'studio/authoring/programs',
              element: <StudioAuthoringProgramsView />,
            },
            {
              path: 'studio/authoring/programs/:programID',
              element: <StudioAuthoringProgramAboutView />,
            },
            {
              path: 'studio/settings',
              element: <StudioAuthoringSettingsView />,
            },

            {
              path: 'downloads',
              element: <DownloadsView />,
            },
            {
              path: 'invites',
              element: <InvitationsView />,
            },
          ],
        },
        {
          path: 'login/complete',
          element: <CompleteLogin />,
        },
        {
          path: 'sso-login',
          element: <SsoLogin />,
          loader: authCheckFn(false, false, appData, setAppData),
        },
        {
          path: 'logout',
          element: (
            <Logout
              redirectTo={`${process.env.REACT_APP_IBL_SPA_AUTH_URL}/login?redirect-to=${window.location.origin}/sso-login`}
            />
          ),
          loader: authCheckFn(false, false, appData, setAppData),
        },
      ],
    },
  ]);

export default route;
