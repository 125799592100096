import { useState, useEffect } from 'react';
import { IBLSpinLoader } from '@iblai/ibl-web-react-common';
import EmptyTableDataInfographic from '../../../../components/EmptyTableDataInfographic/EmptyTableDataInfographic';

const SkillPointsTable = ({courseId, refreshSkillPoints}) => {
    const [skillPoints, setSkillPoints] = useState({});
    const [skillPointsLoaded, setSkillPointsLoaded] = useState(false);

    useEffect(() => {
        fetchSkillPoints();
    }, [courseId, refreshSkillPoints]);

    const fetchSkillPoints = async () => {
          const url = `${process.env.REACT_APP_IBL_DM_URL}/api/catalog/milestones/skill_points/course/?course_id=${encodeURIComponent(courseId)}`;

          fetch(`${url}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Token ${localStorage.getItem('dm_token')}`,
            },
          })
            .then((resp) => {
              if (!resp.ok) {
                setSkillPoints({});
                setSkillPointsLoaded(true);
                throw new Error(`HTTP error! status: ${resp.status}`);
              }
              return resp.json();
            })
            .then((data) => {
              setSkillPoints(data || {});
              setSkillPointsLoaded(true);
            })
            .catch((err) => {
              console.error('Error fetching skill points:', err);
              setSkillPoints({});
              setSkillPointsLoaded(true);
            });
    };

    return (
      <div className="table_cont mgt0" style={{ marginTop: '24px' }}>
        <div className="div-block-202 w_t w_w">
          <div className="div-block-169">
            <div>
              <div className="text-block-109">Course Skill Points</div>
            </div>
          </div>

          <div className="table">
            <div className="th">
              <div className="tc _10">
                <div className="sm">#</div>
              </div>
              <div className="tc">
                <div className="w_sort">
                  <div className="text-block-134 fa_icon sort"></div>
                  <div className="sm">Skill</div>
                </div>
              </div>
              <div className="tc _32-5">
                <div className="w_sort">
                  <div className="text-block-134 fa_icon sort"></div>
                  <div className="sm">Points</div>
                </div>
              </div>
              <div className="tc _32-5">
                <div className="w_sort">
                  <div className="text-block-134 fa_icon sort"></div>
                  <div className="sm">Course ID</div>
                </div>
              </div>
            </div>
            {!skillPointsLoaded ? (
              <IBLSpinLoader
                color={'#FFF'}
                size={20}
                containerHeight={'200px'}
              />
            ) : Object.keys(skillPoints).length === 0 ? (
              <EmptyTableDataInfographic />
            ) : (
              Object.entries(skillPoints).map(([skill, points], index) => (
                <div className="tr" key={skill}>
                  <div className="tc tcell _10">
                    <div className="m">{index + 1}</div>
                  </div>
                  <div className="tc tcell">
                    <span className="nb">{skill}</span>
                  </div>
                  <div className="tc tcell _32-5">
                    <div className="m">{points}</div>
                  </div>
                  <div className="tc tcell _32-5">
                    <div className="m">{courseId}</div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    );
};

export default SkillPointsTable;
