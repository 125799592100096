import { useEffect, useState } from 'react';
import Async from 'react-select/async';
import Select from 'react-select';

import {
  getTenant,
  getUserId,
  IBLSpinLoader,
  isValidHttpUrl,
} from '@iblai/ibl-web-react-common';

const CreateCredential = ({
  setRefreshCredential,
  courseID,
  notification,
  setNotification,
  loading,
  setLoading,
  setCreateCredentialPopupOpen,
}) => {
  const [credentialData, setCredentialData] = useState({
    issuer: '',
    name: '',
    description: '',
    iconImage: '',
    thumbnailImage: '',
    backgroundImage: '',
    credentialType: '',
    criteriaNarrative: '',
    criteriaUrl: '',
    courses: [courseID],
    html_template: '',
    ccs_template: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCredentialData({
      ...credentialData,
      [name]: value,
    });
  };

  const getCredentialIssuers = () => {
    const url = `${process.env.REACT_APP_IBL_DM_URL}/api/credentials/orgs/${getTenant()}/users/${getUserId()}/issuers/`;

    return fetch(`${url}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('axd_token')}`,
      },
    })
      .then((resp) => {
        if (!resp.ok) {
          throw new Error('Failed to fetch credential issuers');
        }
        return resp.json();
      })
      .then((data) => {
        return data.result.data || [];
      })
      .catch((err) => {
        console.error('Error fetching credentials:', err);
        return [];
      });
  };

  const loadIssuerOptions = () => {
    return getCredentialIssuers().then((data) => {
      return data.map((issuer) => ({
        value: issuer.entityId,
        label: issuer.name,
      }));
    });
  };

  const handleIssuerChange = (issuer) => {
    setCredentialData({ ...credentialData, issuer: issuer.value });
  };

  const credentialTypeOptions = [
    { value: 'MICROCREDENTIAL', label: 'Microcredential' },
    { value: 'COURSE_CERTIFICATE', label: 'Course Certificate' },
  ];

  const handleCredentialTypeChange = (credentialType) => {
    setCredentialData({
      ...credentialData,
      credentialType: credentialType.value,
    });
  };

  const postCreateCredential = (data) => {
    const url = `${process.env.REACT_APP_IBL_DM_URL}/api/credentials/orgs/${getTenant()}/users/${getUserId()}/`;

    return fetch(`${url}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('axd_token')}`,
      },
      body: JSON.stringify(data),
    })
      .then((resp) => {
        if (!resp.ok) {
          setNotification({
            success: false,
            msg: 'Something went wrong, please try again.',
          });
          setLoading(false);
          throw new Error('Failed to create credential');
        }
        return resp;
      })
      .then((data) => {
        setCredentialData({
          issuer: '',
          name: '',
          description: '',
          iconImage: '',
          thumbnailImage: '',
          backgroundImage: '',
          credentialType: '',
          criteriaNarrative: '',
          criteriaUrl: '',
          courses: [courseID],
          html_template: '',
          ccs_template: '',
        });
        // setNotification({
        //   success: true,
        //   msg: 'Credential created successfully',
        // });
        setRefreshCredential((prev) => !prev);
        window.alert('Credential created successfully');
        setLoading(false);
        // reset data
      })
      .catch((err) => {
        setNotification({
          success: false,
          msg: err.message || 'Something went wrong, please try again.',
        });
        setLoading(false);
      });
  };

  useEffect(() => {
    // Clear notification when any input changes
    if (credentialData) {
      setNotification({ success: null, msg: null });
    }
  }, [credentialData, courseID]);

  const handleSubmit = () => {
    const {
      issuer,
      name,
      description,
      iconImage,
      credentialType,
      backgroundImage,
    } = credentialData;

    if (
      !issuer ||
      !name ||
      !description ||
      !iconImage ||
      !credentialType ||
      !backgroundImage
    ) {
      setNotification({
        success: false,
        msg: 'All fields must be filled',
      });
      return;
    }

    // icon mage and background image must be valid urls
    if (!isValidHttpUrl(iconImage)) {
      setNotification({
        success: false,
        msg: 'Icon Image must be a valid URL',
      });
      return;
    }

    if (!isValidHttpUrl(backgroundImage)) {
      setNotification({
        success: false,
        msg: 'Background Image must be a valid URL',
      });
      return;
    }

    setLoading(true);
    postCreateCredential(credentialData);
  };

  return (
    <div
      style={{ display: 'flex' }}
      className="popup_div-nm_popup create-credential-popup"
    >
      <div className="popup_cont-sk">
        <div className="hf_popup">
          <div className="div-block-147 new_msg">
            <div className="text-block-135 mg-0">Create Credential</div>
            {notification?.msg && (
              <div
                className={`license-notification-block ${!notification?.success ? 'error' : ''}`}
              >
                {notification?.msg}
              </div>
            )}
          </div>
          <div className="tabs w-tabs">
            <div className="w-tab-content">
              <div className="w-tab-pane w--tab-active">
                <div className="div-block-228 less-pad">
                  <div className="form-block-7 w-form">
                    <form>
                      <label
                        htmlFor="issuer"
                        className="text-block-139-bd-f-soc"
                      >
                        Issuer *
                      </label>
                      <Async
                        cacheOptions
                        classNamePrefix="react-select"
                        defaultOptions
                        loadOptions={loadIssuerOptions}
                        onChange={handleIssuerChange}
                        isSearchable={false}
                      />

                      <label htmlFor="name" className="text-block-139-bd-f-soc">
                        Credential Name *
                      </label>
                      <input
                        className="text-field-3 w-input"
                        name="name"
                        value={credentialData.name}
                        onChange={handleInputChange}
                      />

                      <label
                        htmlFor="description"
                        className="text-block-139-bd-f-soc"
                      >
                        Description *
                      </label>
                      <input
                        className="text-field-3 w-input"
                        name="description"
                        value={credentialData.description}
                        onChange={handleInputChange}
                      />

                      <label
                        htmlFor="credentialType"
                        className="text-block-139-bd-f-soc"
                      >
                        Credential Type *
                      </label>
                      <Select
                        classNamePrefix="react-select"
                        name="credentialType"
                        options={credentialTypeOptions} // Static options go here
                        onChange={handleCredentialTypeChange}
                        isSearchable={false} // Disable typing ability
                      />

                      <label
                        htmlFor="iconImage"
                        className="text-block-139-bd-f-soc"
                      >
                        Icon Image URL *
                      </label>
                      <input
                        className="text-field-3 w-input"
                        name="iconImage"
                        value={credentialData.iconImage}
                        onChange={handleInputChange}
                      />

                      <label
                        htmlFor="backgroundImage"
                        className="text-block-139-bd-f-soc"
                      >
                        Background Image URL *
                      </label>
                      <input
                        className="text-field-3 w-input"
                        name="backgroundImage"
                        value={credentialData.backgroundImage}
                        onChange={handleInputChange}
                      />

                      <label
                        htmlFor="courses"
                        className="text-block-139-bd-f-soc"
                      >
                        Course *
                      </label>
                      <input
                        className="text-field-3 w-input"
                        name="courseId"
                        data-name="Course ID"
                        placeholder="Enter the Course ID"
                        type="text"
                        id="course-id"
                        style={{ backgroundColor: 'transparent' }}
                        value={courseID}
                        readOnly
                      />

                      <label
                        htmlFor="html_template"
                        className="text-block-139-bd-f-soc"
                      >
                        HTML Template
                      </label>
                      <input
                        className="text-field-3 w-input"
                        name="html_template"
                        value={credentialData.html_template}
                        onChange={handleInputChange}
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="div-block-147 new_msg">
            <div className="div-block-264 f-w">
              <div
                onClick={() => setCreateCredentialPopupOpen(false)}
                data-w-id="04b212b6-6908-556e-c3c3-d1f2508be27f"
                className="div-block-207 first active follow-user alt-btn cancel-invite"
              >
                <div className="text-block-111 red-text cf">Close</div>
              </div>
              <div
                onClick={handleSubmit}
                className="div-block-207 first active follow-user redeem-btn mg-l16"
              >
                <div className="text-block-111 red-text cf">Create</div>
                {loading && (
                  <span style={{ marginLeft: '10px' }}>
                    <IBLSpinLoader size={15} color={'#FFF'} />
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCredential;
