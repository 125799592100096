import { IBLSpinLoader } from '@iblai/ibl-web-react-common';
import { useState, useEffect } from 'react';
import Async from 'react-select/async';


const AssignSkill = ({
  notification,
  setNotification,
  loading,
  setLoading,
  courseID,
  setIsSkillPopupOpen,
  setRefreshSkillPoints,
}) => {
  const getSkillAPI = (inputValue) => {
    return fetch(
      `${process.env.REACT_APP_IBL_DM_URL}/api/search/search/?filter={%22_index%22:%22ibl-dm.public.dl_catalog_app_skill%22}&page=1&context=${inputValue}`
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorData) => {
            throw new Error(errorData.detail || 'Failed to fetch skills');
          });
        }
        return response.json();
      })
      .then((data) => {
        return data.results || [];
      })
      .catch((error) => {
        console.error('Error fetching skills:', error);
        return [];
      });
  };


   const fetchSkillPoints = async () => {
     const url = `${process.env.REACT_APP_IBL_DM_URL}/api/catalog/milestones/skill_points/course/?course_id=${encodeURIComponent(courseID)}`;

     try {
       const response = await fetch(url, {
         method: 'GET',
         headers: {
           'Content-Type': 'application/json',
           Authorization: `Token ${localStorage.getItem('dm_token')}`,
         },
       });

       if (!response.ok) {
         throw new Error(`HTTP error! status: ${response.status}`);
       }

       const data = await response.json();
       return data || {};
     } catch (err) {
       console.error('Error fetching skill points:', err);
       return {};
     }
   };


  const postSkillAssign = (data) => {
    const url =
      process.env.REACT_APP_IBL_DM_URL +
      `/api/catalog/milestones/skill_points/course/`;

    fetch(`${url}`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Token ' + localStorage.getItem('dm_token'),
      },
    })
      .then((resp) => {
        console.log(resp, 'response here');
        if (!resp.ok) {
          setNotification({
            success: false,
            msg: 'Something went wrong, please try again.',
          });
          setLoading(false);
          throw new Error('Failed to assign skill');
        }
        return resp;
      })
      .then((data) => {
        setNotification({
          success: true,
          msg: 'Skill points assigned successfully',
        });
        setLoading(false);
        setRefreshSkillPoints((prev) => !prev);
        // Reset form data if needed
        setSelectedSkill(null);
        setSkillPoints('');
      })
      .catch((err) => {
        setNotification({
          success: false,
          msg: err.message || 'Something went wrong, please try again.',
        });
        setLoading(false);
      });

    
  };

  const processSkillPoints = async (data) => {
    const skillPointsData = await fetchSkillPoints();
    const updatedPointData = { ...data.point_data };
 try {
    for (const [skill, points] of Object.entries(skillPointsData)) {
        if (!(skill in updatedPointData)) {
          updatedPointData[skill] = points;
        }
      }
 } catch (error) {
  console.error('Error processing skill points:', error);
 }
      

      postSkillAssign({
        ...data,
        point_data: updatedPointData,
      });
    };


  const [selectedSkill, setSelectedSkill] = useState(null);
  const [skillPoints, setSkillPoints] = useState('');
  const loadSkillPromiseOptions = (inputValue) => {
    return getSkillAPI(inputValue).then((data) => {
      if (Array.isArray(data) && data.length > 0) {
        return data.map((skill) => ({
          value: skill._source.name,
          label: skill._source.name,
        }));
      } else {
        return [];
      }
    });
  };

  const handleSubmit = () => {
    if (
      !selectedSkill ||
      !skillPoints ||
      typeof selectedSkill === 'string' ||
      skillPoints.trim() === ''
    ) {
      setNotification({
        success: false,
        msg: 'All fields must be filled and not be blank',
      });
      return;
    }
    setLoading(true);
   
    processSkillPoints({
      course_id: courseID,
      point_data: {
        [selectedSkill.value]: skillPoints,
      },
    });
  };

  const handleSkillChange = (skill) => {
    setSelectedSkill(skill);
  };

  const handleSkillPointsChange = (e) => {
    setSkillPoints(e.target.value);
  };

  useEffect(() => {
    // Clear notification when any input changes
    if (selectedSkill || skillPoints) {
      setNotification({ success: null, msg: null });
    }
  }, [selectedSkill, skillPoints]);

  return (
    <div
      style={{ display: 'flex' }}
      react-component="InvitePopup"
      className="popup_div-nm_popup assign-license-popup"
    >
      <div className="popup_cont-sk">
        <div className="hf_popup">
          <div className="div-block-147 new_msg">
            <div className="text-block-135 mg-0">Assign Skill to Course</div>
            {notification?.msg && (
              <div
                className={`license-notification-block ${!notification?.success ? 'error' : ''}`}
              >
                {notification?.msg}
              </div>
            )}
          </div>
          <div
            data-current="Platform"
            data-easing="ease"
            data-duration-in="300"
            data-duration-out="100"
            className="tabs w-tabs"
          >
            <div className="w-tab-content">
              <div data-w-tab="Platform" className="w-tab-pane w--tab-active">
                <div className="div-block-228 less-pad">
                  <div className="form-block-7 w-form">
                    <form
                      id="email-form-5"
                      name="email-form-5"
                      data-name="Email Form 5"
                      method="get"
                      data-wf-page-id="66e0598d95b3c617324f4850"
                      data-wf-element-id="04b212b6-6908-556e-c3c3-d1f2508be1ea"
                    >
                      <label
                        htmlFor="field-5"
                        className="text-block-139-bd-f-soc"
                      >
                        Course
                      </label>
                      <input
                        className="text-field-3 w-input"
                        name="courseId"
                        data-name="Course ID"
                        placeholder="Enter the Course ID"
                        type="text"
                        id="course-id"
                        style={{ backgroundColor: 'transparent' }}
                        value={courseID}
                        readOnly
                      />
                      <label
                        htmlFor="field-5"
                        className="text-block-139-bd-f-soc"
                      >
                        Skill
                      </label>
                      <Async
                        cacheOptions
                        classNamePrefix={'react-select'}
                        defaultOptions
                        loadOptions={loadSkillPromiseOptions}
                        onChange={handleSkillChange}
                        value={selectedSkill}
                      />
                      <label
                        htmlFor="field-5"
                        className="text-block-139-bd-f-soc"
                      >
                        Skill Points
                      </label>

                      <input
                        className="text-field-3 w-input"
                        name="name-2"
                        data-name="Name 2"
                        placeholder="Enter Skill Points"
                        type="number"
                        id="skill-points"
                        value={skillPoints}
                        onChange={handleSkillPointsChange}
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="div-block-147 new_msg">
            <div className="div-block-264 f-w">
              <div
                onClick={() => setIsSkillPopupOpen(false)}
                data-w-id="04b212b6-6908-556e-c3c3-d1f2508be27f"
                className="div-block-207 first active follow-user alt-btn cancel-invite"
              >
                <div className="text-block-111 red-text cf">Close</div>
              </div>
              <div
                onClick={() => handleSubmit()}
                className="div-block-207 first active follow-user redeem-btn mg-l16"
              >
                <div className="text-block-111 red-text cf">Assign</div>
                {loading && (
                  <span style={{ marginLeft: '10px' }}>
                    <IBLSpinLoader size={15} color={'#FFF'} />
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignSkill;
