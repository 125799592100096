import { getTenant, getUserId, IBLSpinLoader } from '@iblai/ibl-web-react-common';
import { useState, useEffect } from 'react';
import IssueCredential from '../../SkillsCoursesViewContent/modals/IssueCredential';
import EmptyTableDataInfographic from '../../../../components/EmptyTableDataInfographic/EmptyTableDataInfographic';

const AssertionsTable = ({entityId, courseID, setAssertionPopupOpen, assertionPopupOpen}) => {
  const [assertions, setAssertions] = useState([]);
  const [assertionsLoaded, setAssertionsLoaded] = useState(false);
  const [refreshAssertions, setRefreshAssertions] = useState(false);
  const [loading, setLoading] = useState(false);

  const [notification, setNotification] = useState({
    success: null,
    msg: null,
  });

  useEffect(() => {
    // Fetch assertions from API
    fetchAssertions();
  }, [refreshAssertions]);

  const fetchAssertions = () => {
    const url = `${process.env.REACT_APP_IBL_DM_URL}/api/credentials/orgs/${getTenant()}/users/${getUserId()}/${entityId}/assertions/`;

    fetch(`${url}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('axd_token')}`,
      },
    })
      .then((resp) => {
        if (!resp.ok) {
          setAssertions([]);
          setAssertionsLoaded(true);
          throw new Error(`HTTP error! status: ${resp.status}`);
        }
        return resp.json();
      })
      .then((data) => {
        setAssertions(data[0].data || []);
        setAssertionsLoaded(true);
      })
      .catch((err) => {
        console.error('Error fetching assertions:', err);
        setAssertions([]);
        setAssertionsLoaded(true);
      });
  };

  return (
    <>
      <div className="table_cont mgt0">
        <div className="div-block-202 w_t w_w">
          <div className="div-block-169">
            <div>
              <div className="text-block-109">Assertions</div>
            </div>
          </div>

          <div className="table">
            <div className="th">
              <div className="tc _10">
                <div className="sm">#</div>
              </div>
              <div className="tc _32-5">
                <div className="w_sort">
                  <div className="text-block-134 fa_icon sort"></div>
                  <div className="sm">Name</div>
                </div>
              </div>
              <div className="tc">
                <div className="w_sort">
                  <div className="text-block-134 fa_icon sort"></div>
                  <div className="sm">Issued On</div>
                </div>
              </div>
              <div className="tc">
                <div className="w_sort">
                  <div className="text-block-134 fa_icon sort"></div>
                  <div className="sm">Issuer</div>
                </div>
              </div>
              <div className="tc">
                <div className="w_sort">
                  <div className="text-block-134 fa_icon sort"></div>
                  <div className="sm">Recipient</div>
                </div>
              </div>
              <div className="tc _32-5">
                <div className="w_sort">
                  <div className="text-block-134 fa_icon sort"></div>
                  <div className="sm">Course Name</div>
                </div>
              </div>

              <div className="tc _20">
                <div className="w_sort">
                  <div className="text-block-134 fa_icon sort"></div>
                  <div className="sm">Credential Type</div>
                </div>
              </div>
            </div>
            {!assertionsLoaded ? (
              <IBLSpinLoader
                color={'#FFF'}
                size={20}
                containerHeight={'200px'}
              />
            ) : assertions.length === 0 ? (
              <EmptyTableDataInfographic />
            ) : (
              assertions.map((assertion, index) => (
                <div className="tr" key={assertion.entityId}>
                  <div className="tc tcell _10">
                    <div className="m">{index + 1}</div>
                  </div>
                  <div className="tc tcell _32-5">
                    <img
                      src={
                        assertion.credentialDetails.iconImage ||
                        '/static/media/c4s.8d5b06c3201e869bcf18.jpeg'
                      }
                      alt=""
                      className="r-i"
                      loading="lazy"
                    />
                    <a className="tl-2 np" href="#">
                      <span className="nb" prop-coursetitle="">
                        {assertion.credentialDetails.name}
                      </span>
                    </a>
                  </div>
                  <div className="tc tcell">
                    <div className="m">
                      {new Date(assertion.issuedOn).toLocaleDateString()}
                    </div>
                  </div>
                  <div className="tc tcell">
                    <div className="m">
                      {assertion.credentialDetails.issuerDetails.name}
                    </div>
                  </div>
                  <div className="tc tcell">
                    <div className="m">{assertion.recipient.name}</div>
                  </div>
                  <div className="tc tcell _32-5">
                    <div className="m">
                      {assertion.course ? assertion.course.name : 'N/A'}
                    </div>
                  </div>

                  <div className="tc tcell _20">
                    <div className="m">
                      {assertion.credentialDetails.credentialType}
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>

      {assertionPopupOpen && (
        <IssueCredential
          notification={notification}
          setNotification={setNotification}
          loading={loading}
          setLoading={setLoading}
          entityID={entityId}
          setRefreshAssertions={setRefreshAssertions}
          setAssertionPopupOpen={setAssertionPopupOpen}
          courseID={courseID}
        />
      )}
    </>
  );
};

export default AssertionsTable;
