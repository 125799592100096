export const  getCourseAPI = () => {
       return fetch(
         `${process.env.REACT_APP_IBL_DM_URL}/api/search/catalog/?content=courses`
       )
         .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
         })
         .then((data) => {
           return data.courses.results || [];
         })
         .catch((error) => {
          console.error('Error fetching courses:', error);
          return [];
         });
     };



     